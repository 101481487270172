<template>
	<div class="single-view tool-agent-parser">
		<div class="tool-box">
			<h1>{{ ii('USER_AGENT_PARSER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-area
							v-model="app.query.agent"
							ref="tf"
							:placeholder="ii('INPUT_USER_AGENT_DATA')"
							@change="refreshQuery"
					></s-text-area>
					<div class="submit-box">
						<s-btn icon="search" :loading="isLoading" @click="parse">{{ ii('PARSE') }}</s-btn>
						<s-btn icon="info-circle" :loading="isLoading"  @click="selfAgent">{{ ii('MY_USER_AGENT') }}</s-btn>
					</div>
				</div>
				<div v-if="data">
					<table class="result-tbl">
						<tbody>
							<template v-for="(val, key) in data">
								<tr v-if="val">
									<td>{{ ii(key) }}</td>
									<td>{{ val }}</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/helpers";
	.tool-agent-parser {
		.submit-box {
			margin: 0 auto;
			display: flex;
			display: -webkit-flex;
			display: -moz-flex;
			align-items: center;
			justify-content: space-around;
			padding-top: 4px;
		}
	}
</style>

<script>
	export default {
		data() {
			return {
				data: null,
				isLoading: false
			};
		},
		computed: {

		},
		methods: {
			async parse() {
				let agent = this.app.query.agent;
				if(!agent){
					this.data = null;
					return;
				}
				this.isLoading = true;
				this.data = await this.post('UserAgent.getUserAgentInfo', agent);
				this.isLoading = false;
			},
			selfAgent(){
				let ua = navigator.userAgent;
				this.editQueryParams({agent: ua});
				this.parse();
			}
		},
		watch: {
			'$route'() {
				this.parse();
			}
		},
		mounted() {
			this.$refs.tf.focus();
			this.parse();
		}
	}
</script>